import React from "react"
import { Link } from "gatsby"
import BgImg from "../../images/connect.jpg"
import Layout from "../../components/layoutSurpriseCustom"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Connect with Us"
    SecondText="We want to hear from you"
  >
    <SEO title="Connect - Surprise" />
    <iframe
      src="https://cccares.formstack.com/forms/nextsteps"
      title="Connect - Cross Church"
      width="100%"
      height="1200px"
      frameBorder="0"
    ></iframe>
  </Layout>
)

export default IndexPage
